import { create } from "zustand";

interface FilterStore {
  filter: Filter;
  sort: string;
  pagination: {
    currentPage: number;
    totalPages: number;
  };
  minPrice: number;
  maxPrice: number;
  applyArrayFilter: (
    category: keyof Omit<Filter, "price">,
    value: string
  ) => void;
  setSort: (value: string) => void;
  setPrice: (value: [number, number]) => void;
  setMinMaxPrice: (min: number, max: number) => void;
  setPagination: (page: number, total: number) => void;
  clearFilters: () => void;
}

const DEFAULT_CUSTOM_PRICE = [0, 100] as [number, number];

export const useFilterStore = create<FilterStore>((set) => ({
  filter: {
    color: [],
    brand: [],
    material: [],
    price: DEFAULT_CUSTOM_PRICE,
  },
  sort: "none",
  pagination: {
    currentPage: 1,
    totalPages: 0,
  },
  minPrice: 0,
  maxPrice: 100,
  applyArrayFilter: (category, value) =>
    set((state) => {
      const isFilterApplied = state.filter[category].includes(value);
      return {
        ...state,
        filter: {
          ...state.filter,
          [category]: isFilterApplied
            ? state.filter[category].filter((v) => v !== value)
            : [...state.filter[category], value],
        },
      };
    }),
  setSort: (value) =>
    set((state) => ({
      ...state,
      sort: value,
    })),
  setPrice: (value) =>
    set((state) => ({
      ...state,
      filter: {
        ...state.filter,
        price: value,
      },
    })),
  setMinMaxPrice: (min: number, max: number) =>
    set((state) => ({
      ...state,
      minPrice: min,
      maxPrice: max,
    })),
  setPagination: (page, total) =>
    set((state) => ({
      ...state,
      pagination: {
        currentPage: page,
        totalPages: total,
      },
    })),
  clearFilters: () =>
    set((state) => ({
      ...state,
      filter: {
        color: [],
        brand: [],
        material: [],
        price: [state.minPrice, state.maxPrice],
      },
    })),
}));

import { CircularProgress } from "@material-ui/core";
import { useState } from "react";

const Envia = () => {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  const enviaUrl =
    process.env.NODE_ENV === "production"
      ? "https://ship.envia.com/"
      : "https://ship-test.envia.com/";

  return (
    <section className="layout-dashboard-container">
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <iframe
        style={{
          width: "100%",
          height: "80vh",
          border: "none",
          borderRadius: 8,
          display: loading ? "none" : "block",
        }}
        src={enviaUrl}
        title="Envia-Config"
        allowFullScreen
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        onLoad={handleLoad}
      />
    </section>
  );
};

export default Envia;

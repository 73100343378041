import { Grid, TextField } from "@material-ui/core";
import { Control, Controller, FieldErrors } from "react-hook-form";
import CustomButton from "../../../../../../components/Button/CustomButton";

type NavbarLinksFormProps = {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  control: Control<any>;
  errors: FieldErrors<any>;
  isLoading: boolean;
  isDisabled: boolean;
};

const NavbarLinksForm = ({
  onSubmit,
  control,
  errors,
  isLoading,
  isDisabled,
}: NavbarLinksFormProps) => {
  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4} md={5}>
          <Controller
            name="name"
            control={control}
            render={({ onChange, onBlur, value, name, ref }) => (
              <TextField
                size="small"
                label="Nombre"
                variant="outlined"
                fullWidth
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                disabled={isDisabled}
                inputRef={ref}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={5}>
          <Controller
            name="url"
            control={control}
            render={({ onChange, onBlur, value, name, ref }) => (
              <TextField
                size="small"
                label="URL"
                variant="outlined"
                fullWidth
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                disabled={isDisabled}
                name={name}
                inputRef={ref}
                error={!!errors.url}
                helperText={errors.url?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={2}>
          <CustomButton
            size="small"
            type="submit"
            color="secondary"
            shape="little-rounded"
            isLoading={isLoading}
            disabled={isLoading || isDisabled}
          >
            Agregar
          </CustomButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default NavbarLinksForm;

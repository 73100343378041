import { yupResolver } from "@hookform/resolvers";
import {
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { InferType } from "yup";
import CustomButton from "../../../../../../components/Button/CustomButton";
import { enviaKeySchema } from "../../../../../../services/formValidation";
import { useUpdateEnviaPublicKey } from "../../../../../../utils/react-query/querySupplier/queries";
import { Link } from "react-router-dom";

const EnviaKey = ({ initialPublicKey }: { initialPublicKey: string }) => {
  const { handleSubmit, control, errors } = useForm<
    InferType<typeof enviaKeySchema>
  >({
    resolver: yupResolver(enviaKeySchema),
    defaultValues: {
      publicKey: initialPublicKey || "",
    },
  });
  const { mutateAsync: updateEnviaKey, isLoading: isUpdatingEnviaKey } =
    useUpdateEnviaPublicKey();

  const [showKey, setShowKey] = useState(false);

  const onSubmit = async (data: InferType<typeof enviaKeySchema>) => {
    if (!data.publicKey) return;
    await updateEnviaKey({ enviaKey: data.publicKey });
  };

  return (
    <Paper className="paper-container">
      <div className="enviakey-content">
        <div className="enviakey-header">
          <h1>Llave Pública de Envia</h1>
          <p>
            La llave pública de Envia es necesaria para programar el envío de
            tus productos. Asegúrate de ingresar la llave correcta para que
            todas las transacciones de envío se realicen a nombre de la cuenta
            con la cual se creó.
          </p>
        </div>
        <hr className="hr-separator" />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(onSubmit)();
          }}
        >
          <Controller
            name="publicKey"
            control={control}
            render={({ onChange, onBlur, value, name, ref }) => (
              <TextField
                type={showKey ? "text" : "password"}
                label="Llave Pública"
                variant="outlined"
                fullWidth
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                inputRef={ref}
                error={!!errors.publicKey}
                helperText={errors.publicKey ? errors.publicKey.message : ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle key visibility"
                        onClick={() => setShowKey(!showKey)}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showKey ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          <CustomButton
            className="enviakey-button-submit"
            type="submit"
            color="secondary"
            shape="little-rounded"
            size="medium"
            isLoading={isUpdatingEnviaKey}
          >
            Guardar Llave
          </CustomButton>
        </form>
      </div>
      {!initialPublicKey && (
        <div className="envia-config">
          <Link to={"/proveedor/envios"}>
            <p>¿Cómo obtener la llave pública de Envia?</p>
          </Link>
        </div>
      )}
    </Paper>
  );
};

export default EnviaKey;

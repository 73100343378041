import { useMutation, useQuery, useQueryClient } from "react-query";

import { fetchCityOptions } from "../../../Checkout/services/getCities";
import {
  deleteAddressById,
  getAllAddresses,
} from "../../../_virtual-consultancy/services/api";
import { successToast, errorToast } from "../../../services/toasts";
import { getShippingCost, getShippingCostByCompanyName } from "../../../Checkout/services/api";
import { QUERY_KEYS } from "../queryKeys";

export const useGetCities = () => {
  return useQuery([QUERY_KEYS.GET_CITIES], fetchCityOptions, {
    staleTime: 3600000,
  });
};

export const useGetAllAddresses = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_ALL_ADDRESSES],
    queryFn: getAllAddresses,
  });
};

export const useDeleteAddress = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => deleteAddressById(id),
    onSuccess: () => {
      queryClient.invalidateQueries("addresses");
      successToast("Dirección eliminada con éxito");
    },
    onError: () => {
      errorToast("Hubo un error al eliminar la dirección");
    },
  });
};

export const useGetShippingCost = (shouldFetch: boolean) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_SHIPPING_COST],
    queryFn: () => getShippingCost(),
    refetchOnWindowFocus: false,
    enabled: shouldFetch,
    onError: () => {
      errorToast(
        "No se pudo cargar el costo de envío, revisa tus datos de envío"
      );
    },
  });
};

export const useGetShippingCostByCompanyName = (shouldFetch: boolean, companyName: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_SHIPPING_COST_BY_COMPANY_NAME, companyName],
    queryFn: () => getShippingCostByCompanyName(companyName),
    refetchOnWindowFocus: false,
    enabled: shouldFetch,
    onError: () => {
      errorToast(
        "No se pudo cargar el costo de envío, revisa tus datos de envío"
      );
    },
  });
};

import CancelIcon from "@material-ui/icons/Cancel";
import { useCallback } from "react";
import styles from "./Modal.module.scss";

interface ModalProps {
  isOpen: boolean;
  disabled?: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  body?: React.ReactNode;
}

const Modal = ({ isOpen, disabled, onClose, body }: ModalProps) => {
  const handleClose = useCallback(() => {
    if (disabled) return;
    setTimeout(() => {
      onClose();
    }, 300);
  }, [disabled, onClose]);

  return (
    <>
      <div
        className={`${styles.container}  ${
          isOpen ? styles.translatey0 : styles.translatey100
        }`}
      >
        <div
          className={`${styles.wraper} ${
            isOpen ? styles.translatey0 : styles.translatey100
          } `}
        >
          <div onClick={handleClose} className={styles.closeIcon}>
            <CancelIcon fontSize="large" />
          </div>

          <div className={styles.body}>{body}</div>
        </div>
      </div>
    </>
  );
};
export default Modal;

import {
  createStore,
  setStorageType,
  StateMachineProvider,
} from "little-state-machine";
import { Redirect, Route, Switch } from "react-router-dom";
import { toast } from "react-toastify";
import { AccessLevels, AuthProvider, isLoggedIn } from "./services/auth";

import SimpleReactLightbox from "simple-react-lightbox";
import Layout from "../src/privatePages/layout/components/layout";
import PrivateRoute from "./services/privateRoute.js";

import "react-toastify/dist/ReactToastify.css";

// General
import HomeLogin from "./pages/home/HomeLogin.js";
import HomeRegister from "./pages/home/HomeRegister";
// import PresentialConsultancy from "./pages/home/PresentialConsultancy";
import PasswordReset from "./pages/PasswordReset.js";

import DesignerFormStep1 from "./pages/WWU/DesignerFormStep1";
import DesignerFormStep2 from "./pages/WWU/DesignerFormStep2";

import VideoAPI from "./components/VideoAPI/VideoAPI";

// Designers
import DesignerConfig from "./privatePages/designer/Config.js";
import ConsultancyQuoter from "./privatePages/designer/consultancyQuoter.js";
import DesignerInbox from "./privatePages/designer/inbox.js";
import MembershipRequired from "./privatePages/designer/membershipRequired.js";
// import ProductsConsultancyManagement from "./privatePages/designer/productsConsultancyManagement";
// import DesignerProductsManagement from "./privatePages/designer/productsManagement.js";
import PurchasesManagement from "./privatePages/designer/PurchasesManagement.js";
import Quoter from "./privatePages/designer/quoter.js";
import Quoter2 from "./privatePages/designer/Quoter2.js";
import DesignerStatisticsManagement from "./privatePages/designer/Stats.js";

// Admin
import AdminProducts from "./privatePages/admin/AdminProducts";
import ClientsManagement from "./privatePages/admin/ClientsManagement";
// import AdminCategories from "./privatePages/admin/componenets/categories/AdminCategories";
import CreateMessage from "./privatePages/admin/createMessage.js";
import DesignersManagement from "./privatePages/admin/DesignersManagement";
// import PresentialConsultanciesManagement from "./privatePages/admin/PresentialConsultanciesManagement.js";
import AdminProductsManagement from "./privatePages/admin/productsManagement.js";
import RemodelingsManagement from "./privatePages/admin/RemodelingsManagement.js";
import SuppliersManagement from "./privatePages/admin/SuppliersManagement";
import VirtualConsultanciesManagement from "./privatePages/admin/VirtualConsultanciesManagement";

// LSH
import LSHProducts from "./privatePages/lsh/LSHProducts";
import PayedPurchasesManagement from "./privatePages/lsh/payedPurchasesManagement.js";
import LSHProductsManagement from "./privatePages/lsh/productsManagement.js";

// Client
import RemodelingResume from "./pages/RemodelingResume";
import Checkout from "./privatePages/client/checkout.js";
import Checkout2 from "./privatePages/client/Checkout2";
import ConsultancyInvoicesCheckout from "./privatePages/client/ConsultancyInvoicesCheckout";
import DesignsCheckout from "./privatePages/client/DesignsCheckout";

// import PresentialConsultancyResume from "./privatePages/PresentialConsultancyResume";
import VirtualConsultancyResume from "./privatePages/virtualConsultancyResume/virtualConsultancyResume";

// Suppliers
import SupplierFormStep1 from "./pages/WWU/SupplierFormStep1";
import SupplierFormStep2 from "./pages/WWU/SupplierFormStep2";
import ClientConfig from "./privatePages/client/Config";
import MyConsultancies from "./privatePages/client/MyConsultancies";
import PQRS from "./privatePages/client/PQRS";
// import MyPresentialConsultancies from "./privatePages/client/presentialConsultancyResume/MyPresentialConsultancies";
import VirtualConsultanciesList from "./privatePages/designer/VirtualConsultanciesList";
import BrandsManagement from "./privatePages/supplier/BrandsManagement";
import SupplierCoatings from "./privatePages/supplier/Coatings";
import Envia from "./privatePages/supplier/pages/Envia/Envia";
import SupplierProducts from "./privatePages/supplier/components/Products/SupplierProducts";
import SupplierConfig from "./privatePages/supplier/Config";
import SupplierDecoratives from "./privatePages/supplier/Decoratives";
import SupplierStatisticsManagement from "./privatePages/supplier/Stats.js";

import { lazy } from "react";
import OrdersAdminTable from "./privatePages/admin/pages/OrdersAdminTable/OrdersAdminTable";
import PurchasesAdminTable from "./privatePages/admin/pages/PurchasesAdminTable/PurchasesAdminTable";
import SuppliersAdminTable from "./privatePages/admin/pages/SuppliersAdminTable/SuppliersAdminTable";
import OrderDetailsPage from "./privatePages/dashboard/pages/orderDetailsPage/OrderDetailsPage";
import ProductReviewPage from "./privatePages/dashboard/pages/productReviewPage/ProductReviewPage";
import PurchaseDetailPage from "./privatePages/dashboard/pages/purchaseDetailPage/PurchaseDetailPage";
import PurchasesPage from "./privatePages/dashboard/pages/purchasesPage/PurchasesPage";
import ProductForm from "./privatePages/supplier/components/Products/ProductForm";
import Preferences from "./privatePages/supplier/pages/Preferences/Preferences";
import ProductEditPage from "./privatePages/supplier/pages/ProductEditor/ProductEditPage";
import PurchasesSupplierTable from "./privatePages/supplier/pages/PurchasesSupplierTable/PurchasesSupplierTable";
import OrdersSupplierTable from "./privatePages/supplier/pages/salesManager/OrdersSupplierTable";
import SalesPersonCreate from "./privatePages/supplier/pages/SalesPersonCreate/SalesPersonCreate";
import SalesPersonEdit from "./privatePages/supplier/pages/SalesPersonEdit/SalesPersonEdit";
import SalesManagement from "./privatePages/supplier/SalesManagement";
import { CartProvider } from "./Store";
import { FilterProvider } from "./Store/Filters/context/FilterContext";
import { ProductProvider } from "./Store/Products/context/ProductContext";
import SalesPersonList from "./privatePages/supplier/pages/SalesPersonList/SalesPersonList";

// Components with lazy loading
const Ecommerce = lazy(() => import("./ecommerce/layout/EcommerceLayout"));
const SupplierEcommerce = lazy(() =>
  import("./supplierEcommerce/layout/EcommerceLayout")
);
const CheckoutEcommerce = lazy(() =>
  import("./Checkout/layout/CheckoutLayout")
);
const SupplierCheckoutEcommerce = lazy(() =>
  import("./supplierCheckout/layout/CheckoutLayout")
);
const VirtualConsultancyLayout = lazy(() =>
  import("./_virtual-consultancy/layout/VirtualConsultancyLayout")
);

toast.configure();

setStorageType({
  setItem: () => {},
  getItem: () => {},
  ...localStorage,
});

createStore({
  data: {},
});

const roleRedirects = {
  1: "/productos",
  2: "/productos",
  3: "/proveedor/dashboard",
  4: "/lsh/dashboard",
  5: "/admin/dashboard",
};

function App() {
  return (
    <SimpleReactLightbox>
      <StateMachineProvider>
        <AuthProvider>
          <ProductProvider>
            <FilterProvider>
              <CartProvider>
                <Switch>
                  <Route exact path="/register">
                    {isLoggedIn.loggedRol ? (
                      <Redirect to={roleRedirects[isLoggedIn.loggedRol]} />
                    ) : (
                      <HomeRegister />
                    )}
                  </Route>
                  <Route path="/login">
                    {isLoggedIn.loggedRol ? (
                      <Redirect to={roleRedirects[isLoggedIn.loggedRol]} />
                    ) : (
                      <HomeLogin />
                    )}
                  </Route>
                  {/* TODO: in the future implement presential consultancy management
              <Route
                path="/asesoria-presencial"
                component={PresentialConsultancy}
              /> */}
                  <Route path="/password-reset" component={PasswordReset} />
                  {/* Routes for the ecommerce */}
                  <Route path="/productos" component={Ecommerce} />
                  {/* Routes for the Supplier Ecommerce */}
                  <Route
                    path="/:companyName/productos"
                    component={SupplierEcommerce}
                  />
                  {/* Routes for checkout */}
                  <Route
                    path="/:companyName/pago"
                    component={SupplierCheckoutEcommerce}
                  />
                  <Route path="/pago" component={CheckoutEcommerce} />
                  {/* Routes for virtual consultancy */}
                  <Route
                    path="/asesoria-virtual"
                    component={VirtualConsultancyLayout}
                  />
                  {/* {/* TODO: check if in the future both sections would be modified with videos and information from fliping */}
                  {/* <Route
              path="/disenador/trabaja-con-nosotros"
              component={WorkWithUs}
            />
            <Route
              path="/proveedor/trabaja-con-nosotros"
              component={WorkWithUsSuppliers}
            />  */}
                  <Route
                    path="/disenador/paso-1"
                    component={DesignerFormStep1}
                  />
                  <Route
                    path="/disenador/paso-2"
                    component={DesignerFormStep2}
                  />
                  <Route
                    path="/proveedor/paso-1"
                    component={SupplierFormStep1}
                  />
                  <Route
                    path="/proveedor/paso-2"
                    component={SupplierFormStep2}
                  />
                  {/* TODO: Check if there is another way to pass the role with a query instead of a param on the url */}
                  <Route path="/videochat/:hashid/:role" component={VideoAPI} />
                  <Route
                    path="/disenador/membership-required"
                    component={MembershipRequired}
                  />
                  {/* Private routes (client) */}
                  {/* TODO: This route should re-direct you to home if the client is not the one that made the purchase */}
                  <PrivateRoute
                    path="/cliente/configuracion/"
                    accessLevel={AccessLevels.client}
                    render={() => (
                      <Layout
                        headerTitle="Mi cuenta"
                        content={<ClientConfig />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/cliente/dashboard/"
                    accessLevel={AccessLevels.client}
                    render={() => (
                      <Layout
                        headerTitle="Mis compras"
                        content={<MyConsultancies />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/cliente/asesorias/"
                    accessLevel={AccessLevels.client}
                    render={() => (
                      <Layout
                        headerTitle="Mis Asesorías"
                        content={<MyConsultancies />}
                      />
                    )}
                  />
                  {/* TODO: in the future implement presential consultancy management
              <PrivateRoute
                path="/cliente/asesorias-presenciales/"
                accessLevel={AccessLevels.client}
                render={() => (
                  <Layout
                    headerTitle="Mis Asesorías Presenciales"
                    content={<MyPresentialConsultancies />}
                  />
                )}
              /> */}
                  <PrivateRoute
                    path="/mis-compras/lista"
                    accessLevel={[AccessLevels.client, AccessLevels.designer]}
                    render={() => (
                      <Layout
                        headerTitle="Mis Compras"
                        content={<PurchasesPage />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/mis-compras/detalle/:id"
                    accessLevel={[AccessLevels.client, AccessLevels.designer]}
                    render={() => (
                      <Layout
                        headerTitle="Detalle de Compra"
                        content={<PurchaseDetailPage />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/mis-compras/calificar/:id"
                    accessLevel={AccessLevels.client}
                    render={() => (
                      <Layout
                        headerTitle="Calificar Producto"
                        content={<ProductReviewPage />}
                      />
                    )}
                  />
                  {/* TODO: in the future implement presential consultancy management
              <PrivateRoute
                path="/cliente/compra/asesoria-presencial/:hashid"
                accessLevel={AccessLevels.client}
                render={() => <PresentialConsultancyResume />}
              /> */}

                  <PrivateRoute
                    path="/cliente/compra/asesoria-virtual/:hashid"
                    accessLevel={AccessLevels.client}
                    render={() => <VirtualConsultancyResume />}
                  />
                  <PrivateRoute
                    path="/cliente/pqrs/"
                    accessLevel={AccessLevels.client}
                    render={() => (
                      <Layout headerTitle="PQRS" content={<PQRS />} />
                    )}
                  />
                  <PrivateRoute
                    path="/compra/:hashid"
                    accessLevel={AccessLevels.any}
                    render={() => <RemodelingResume />}
                  />
                  <PrivateRoute
                    path="/checkout2/:hashid"
                    accessLevel={AccessLevels.any}
                    render={() => <Checkout2 />}
                  />
                  <PrivateRoute
                    path="/checkout/diseño/:hashid"
                    accessLevel={AccessLevels.client}
                    render={() => <DesignsCheckout />}
                  />
                  <PrivateRoute
                    path="/checkout/cotizacion/:hashid"
                    accessLevel={AccessLevels.client}
                    render={() => <ConsultancyInvoicesCheckout />}
                  />
                  <PrivateRoute
                    path="/checkout/:hashid"
                    accessLevel={AccessLevels.client}
                    render={() => <Checkout />}
                  />

                  {/* Private routes (designer) */}
                  <PrivateRoute
                    path="/disenador/mensajeria/"
                    accessLevel={AccessLevels.designer}
                    render={() => (
                      <Layout
                        headerTitle="Mensajería"
                        content={<DesignerInbox />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/disenador/dashboard/"
                    accessLevel={AccessLevels.designer}
                    render={() => (
                      <Layout
                        headerTitle="Mis clientes"
                        content={<PurchasesManagement />}
                      />
                    )}
                  />
                  {/* TODO: in the future implement presential consultancy management
              <PrivateRoute
                path="/diseñador/compra/asesoria-presencial/:hashid"
                accessLevel={AccessLevels.designer}
                render={() => <PresentialConsultancyResume />}
              /> */}
                  <PrivateRoute
                    path="/disenador/mis-asesorias-virtuales/"
                    accessLevel={AccessLevels.designer}
                    render={() => (
                      <Layout
                        headerTitle="Mis asesorías virtuales"
                        content={<VirtualConsultanciesList />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/disenador/estadisticas/"
                    accessLevel={AccessLevels.designer}
                    render={() => (
                      <Layout
                        headerTitle="Estadísticas"
                        content={<DesignerStatisticsManagement />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/disenador/compra/asesoria-virtual/:hashid"
                    accessLevel={AccessLevels.designer}
                    render={() => <VirtualConsultancyResume />}
                  />
                  <PrivateRoute
                    path="/disenador/configuracion/"
                    accessLevel={AccessLevels.designer}
                    render={() => (
                      <Layout
                        headerTitle="Configuración"
                        content={<DesignerConfig />}
                      />
                    )}
                  />
                  {/* TODO: in the future implement presential consultancy management 
                  <PrivateRoute
                    path="/disenador/productos"
                    accessLevel={AccessLevels.designer}
                    render={() => (
                      <Layout
                        headerTitle="Gestión de productos"
                        content={<DesignerProductsManagement />}
                      />
                    )}
                  /> */}
                  <PrivateRoute
                    path="/disenador/cotizador"
                    accessLevel={AccessLevels.designer}
                    render={() => (
                      <Layout headerTitle="Cotizador" content={<Quoter />} />
                    )}
                  />
                  <PrivateRoute
                    path="/disenador/cotizador2"
                    accessLevel={AccessLevels.designer}
                    render={() => (
                      <Layout headerTitle="Cotizador" content={<Quoter2 />} />
                    )}
                  />

                  {/* presential consultancy routes for designer */}
                  {/* TODO: in the future implement presential consultancy management
              <PrivateRoute
                path="/disenador/asesoria/productos"
                accessLevel={AccessLevels.designer}
                render={() => (
                  <Layout
                    headerTitle="Gestión de productos de la asesoría"
                    content={<ProductsConsultancyManagement />}
                  />
                )}
              /> */}
                  <PrivateRoute
                    path="/disenador/asesoria/cotizador"
                    accessLevel={AccessLevels.designer}
                    render={() => (
                      <Layout
                        headerTitle="Cotizador"
                        content={<ConsultancyQuoter />}
                      />
                    )}
                  />

                  {/* Private routes (supplier) */}
                  <PrivateRoute
                    path="/proveedor/dashboard"
                    accessLevel={AccessLevels.supplier}
                    render={() => (
                      <Layout
                        headerTitle="Dashboard"
                        content={<SalesManagement />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/proveedor/marcas"
                    accessLevel={AccessLevels.supplier}
                    render={() => (
                      <Layout
                        headerTitle="Marcas"
                        content={<BrandsManagement />}
                      />
                    )}
                  />

                  <PrivateRoute
                    path="/proveedor/ventas/:id"
                    accessLevel={AccessLevels.supplier}
                    render={() => (
                      <Layout
                        headerTitle="Detalle de venta"
                        content={<OrderDetailsPage />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/proveedor/ventas"
                    accessLevel={AccessLevels.supplier}
                    render={() => (
                      <Layout
                        headerTitle="Ventas"
                        content={<PurchasesSupplierTable />}
                      />
                    )}
                  />

                  <PrivateRoute
                    path="/proveedor/envios"
                    accessLevel={AccessLevels.supplier}
                    render={() => (
                      <Layout headerTitle="Envíos" content={<Envia />} />
                    )}
                  />

                  <PrivateRoute
                    exact
                    path="/proveedor/ordenes/detalle/:orderId"
                    accessLevel={AccessLevels.supplier}
                    render={() => (
                      <Layout
                        headerTitle="Detalle de la orden"
                        content={<OrderDetailsPage userType="suppliers" />}
                      />
                    )}
                  />

                  <PrivateRoute
                    path="/proveedor/ordenes/:id"
                    accessLevel={AccessLevels.supplier}
                    render={() => (
                      <Layout
                        headerTitle="Ventas"
                        content={<OrdersSupplierTable />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/proveedor/revestimientos"
                    accessLevel={AccessLevels.supplier}
                    render={() => (
                      <Layout
                        headerTitle="Revestimientos"
                        content={<SupplierCoatings />}
                      />
                    )}
                  />

                  <PrivateRoute
                    path="/proveedor/decorativos"
                    accessLevel={AccessLevels.supplier}
                    render={() => (
                      <Layout
                        headerTitle="Decorativos"
                        content={<SupplierDecoratives />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/proveedor/producto/lista"
                    accessLevel={AccessLevels.supplier}
                    render={() => (
                      <Layout
                        headerTitle="Productos"
                        content={<SupplierProducts />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/proveedor/producto/nuevo"
                    accessLevel={AccessLevels.supplier}
                    render={() => (
                      <Layout
                        headerTitle="Productos"
                        content={<ProductForm />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/proveedor/producto/:id/editar"
                    accessLevel={AccessLevels.supplier}
                    render={() => (
                      <Layout
                        headerTitle="Editar producto"
                        content={<ProductEditPage />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/proveedor/estadisticas/"
                    accessLevel={AccessLevels.supplier}
                    render={() => (
                      <Layout
                        headerTitle="Estadísticas"
                        content={<SupplierStatisticsManagement />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/proveedor/preferencias"
                    accessLevel={AccessLevels.supplier}
                    render={() => (
                      <Layout
                        headerTitle="Preferencias"
                        content={<Preferences />}
                      />
                    )}
                  />

                  <PrivateRoute
                    path="/proveedor/asesor/lista"
                    accessLevel={AccessLevels.supplier}
                    render={() => (
                      <Layout
                        headerTitle="Preferencias"
                        content={<SalesPersonList />}
                      />
                    )}
                  />

                  <PrivateRoute
                    path="/proveedor/asesor/nuevo"
                    accessLevel={AccessLevels.supplier}
                    render={() => (
                      <Layout
                        headerTitle="Preferencias"
                        content={<SalesPersonCreate />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/proveedor/asesor/:asesorId/editar"
                    accessLevel={AccessLevels.supplier}
                    render={() => (
                      <Layout
                        headerTitle="Preferencias"
                        content={<SalesPersonEdit />}
                      />
                    )}
                  />

                  <PrivateRoute
                    path="/proveedor/configuracion"
                    accessLevel={AccessLevels.supplier}
                    render={() => (
                      <Layout
                        headerTitle="Configuración"
                        content={<SupplierConfig />}
                      />
                    )}
                  />
                  {/* Private routes (admin) */}

                  <PrivateRoute
                    path="/admin/mensajeria/"
                    accessLevel={AccessLevels.admin}
                    render={() => (
                      <Layout
                        headerTitle="Mensajería"
                        content={<CreateMessage />}
                      />
                    )}
                  />

                  <PrivateRoute
                    exact
                    path="/admin/ordenes/detalle/:orderId"
                    accessLevel={AccessLevels.admin}
                    render={() => (
                      <Layout
                        headerTitle="Detalle de la orden"
                        content={<OrderDetailsPage userType="admin" />}
                      />
                    )}
                  />

                  <PrivateRoute
                    path="/admin/ventas/:purchaseId/proveedores/:supplierId/ordenes"
                    accessLevel={AccessLevels.admin}
                    render={() => (
                      <Layout
                        headerTitle="Detalle de las ventas"
                        content={<OrdersAdminTable />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/admin/ventas/:id/proveedores"
                    accessLevel={AccessLevels.admin}
                    render={() => (
                      <Layout
                        headerTitle="Detalle de los proveedores"
                        content={<SuppliersAdminTable />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/admin/ventas/"
                    accessLevel={AccessLevels.admin}
                    render={() => (
                      <Layout
                        headerTitle="Ventas"
                        content={<PurchasesAdminTable />}
                      />
                    )}
                  />

                  <PrivateRoute
                    path="/admin/dashboard/"
                    accessLevel={AccessLevels.admin}
                    render={() => (
                      <Layout
                        headerTitle="Gestión de la plataforma"
                        content={<DesignersManagement />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/admin/proveedores/"
                    accessLevel={AccessLevels.admin}
                    render={() => (
                      <Layout
                        headerTitle="Gestión de proveedores"
                        content={<SuppliersManagement />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/admin/remodelaciones"
                    accessLevel={AccessLevels.admin}
                    render={() => (
                      <Layout
                        headerTitle="Gestión de hojas de proceso"
                        content={<RemodelingsManagement />}
                      />
                    )}
                  />
                  {/* TODO: in the future implement presential consultancy management
              <PrivateRoute
                path="/admin/asesorías-presenciales"
                accessLevel={AccessLevels.admin}
                render={() => (
                  <Layout
                    headerTitle="Gestión de asesorías presenciales"
                    content={<PresentialConsultanciesManagement />}
                  />
                )}
              />
              <PrivateRoute
                path="/admin/compra/asesoria-presencial/:hashid"
                accessLevel={AccessLevels.admin}
                render={() => (
                  <Layout
                    headerTitle="Hoja de proceso de asesoría presencial para administrador"
                    content={<PresentialConsultancyResume />}
                  />
                )}
              /> */}
                  <PrivateRoute
                    path="/admin/asesorías-virtuales"
                    accessLevel={AccessLevels.admin}
                    render={() => (
                      <Layout
                        headerTitle="Gestión de asesorías virtuales"
                        content={<VirtualConsultanciesManagement />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/admin/compra/asesoria-virtual/:hashid"
                    accessLevel={AccessLevels.admin}
                    render={() => (
                      <Layout
                        headerTitle="Hoja de proceso de asesorías virtuales para administrador"
                        content={<VirtualConsultancyResume />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/admin/clientes"
                    accessLevel={AccessLevels.admin}
                    render={() => (
                      <Layout
                        headerTitle="Gestión de clientes"
                        content={<ClientsManagement />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/admin/disenadores"
                    accessLevel={AccessLevels.admin}
                    render={() => (
                      <Layout
                        headerTitle="Gestión de diseñadores"
                        content={<DesignersManagement />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/admin/revision-productos"
                    accessLevel={AccessLevels.admin}
                    render={() => (
                      <Layout
                        headerTitle="Revisión de productos"
                        content={<AdminProductsManagement />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/admin/lista-productos"
                    accessLevel={AccessLevels.admin}
                    render={() => (
                      <Layout
                        headerTitle="Productos"
                        content={<AdminProducts />}
                      />
                    )}
                  />
                  {/* TODO: In the future implement category creation section with selection fields for each new category to be created
                  <PrivateRoute
                    path="/admin/categorias"
                    accessLevel={AccessLevels.admin}
                    render={() => (
                      <Layout
                        headerTitle="Productos"
                        content={<AdminCategories />}
                      />
                    )}
                  /> */}
                  <PrivateRoute
                    path="/lsh/dashboard"
                    accessLevel={AccessLevels.lsh}
                    render={() => (
                      <Layout
                        headerTitle="Órdenes pagadas"
                        content={<PayedPurchasesManagement />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/lsh/revision-productos"
                    accessLevel={AccessLevels.lsh}
                    render={() => (
                      <Layout
                        headerTitle="Revisión de productos"
                        content={<LSHProductsManagement />}
                      />
                    )}
                  />
                  <PrivateRoute
                    path="/lsh/lista-productos"
                    accessLevel={AccessLevels.lsh}
                    render={() => (
                      <Layout
                        headerTitle="Productos"
                        content={<LSHProducts />}
                      />
                    )}
                  />
                  {/* If it doesn't find the route, re-direct to login */}
                  <Redirect to="/login" />
                </Switch>
              </CartProvider>
            </FilterProvider>
          </ProductProvider>
        </AuthProvider>
      </StateMachineProvider>
    </SimpleReactLightbox>
  );
}

export default App;

import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

import { ButtonProps } from "../../ecommerce/models";

const CustomButton = ({
  disabled,
  children,
  color,
  shape,
  icon: Icon,
  size = "medium",
  isLoading,
  block,
  className,
  onClick,
  type,
  ...rest
}: ButtonProps) => {
  return (
    <StyledButton
      {...rest}
      type={type}
      color={color}
      shape={shape}
      size={size}
      disabled={disabled}
      onClick={onClick}
      isLoading={isLoading}
      className={className}
      block={block}
    >
      {isLoading ? (
        <>
          <CircularProgress size={20} color="inherit" />
          <div style={{ visibility: "hidden" }}>
            {Icon && <Icon />}
            <span>{children}</span>
          </div>
        </>
      ) : (
        <>
          {Icon && <Icon />}
          <span>{children}</span>
        </>
      )}
    </StyledButton>
  );
};

const StyledButton = styled.button<ButtonProps>`
  /* Flexbox styles */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  ${(props) => props.block && `width: 100%;`}

  /* Dimension styles */
  padding: 12px 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  svg {
    margin-right: 8px;
    font-size: 1.2rem;
  }
  @media (max-width: 768px) {
    svg {
      display: none;
    }
  }

  /* Font styles */
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  font-weight: 500;

  /* Color styles */
  border: 0;
  color: #000000;
  cursor: pointer;

  /* Pseudo-element styles */
  transition: all 0.2s ease;

  :hover {
    /* Hover effect */
    filter: brightness(1.07);
  }

  /* Circular progress style */
  .MuiCircularProgress-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    /* Remove hover effect when disabled */
    :hover {
      filter: none;
    }
  }

  /* Adapt the colors */
  ${(props) =>
    props.color === "primary" &&
    `border: none; background-color: #f8bd43; color: #252525;`}
  ${(props) =>
    props.color === "secondary" &&
    `border: none; background-color: #252525; color: #FFFFFF; &&:hover {background-color: #3b3b3b;}`}
  ${(props) =>
    props.color === "success" &&
    `border: none; background-color: #52BE80; color: #FFFFFF;`}
  ${(props) =>
    props.color === "warning" &&
    `border: none; background-color: #FFD966; color: #252525;`}
  ${(props) =>
    props.color === "danger" &&
    `border: none; background-color: #EC7063; color: #FFFFFF;`}
  ${(props) =>
    props.color === "outlined" &&
    `border: 1px solid rgba(0,0,0,0.5); box-shadow: none; background-color: transparent; color: #252525; &&:hover {background-color: #e4e4e4; color: #252525;}`}

  /* Adapt size of icon */
  ${(props) =>
    props.size === "small" &&
    `svg {
      font-size: 1.3rem;
    }`}
  ${(props) =>
    props.size === "medium" &&
    `svg {
      font-size: 1.5rem;
    }`}
  ${(props) =>
    props.size === "big" &&
    `svg {
      font-size: 1.7rem;
    }`}

  /* Adapt the shape */
  ${(props) => props.shape === "little-rounded" && `border-radius: 4px;`}
  ${(props) => props.shape === "pill" && `border-radius: 25px;`}

  /* Adapt the size */
  ${(props) => props.size === "small" && `font-size: 12px; padding: 8px 16px; `}
  ${(props) =>
    props.size === "medium" && `font-size: 14px; padding: 12px 24px;`}
  ${(props) => props.size === "big" && `font-size: 16px; padding: 16px 32px;`}
`;

export default CustomButton;

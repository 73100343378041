import { yupResolver } from "@hookform/resolvers";
import { Paper } from "@material-ui/core";
import { useState } from "react";
import { useForm } from "react-hook-form";
import DeleteConfirmationDialog from "../../../../../../components/DeleteConfirmationDialog/DeleteConfirmationDialog";
import { navbarLinksFormSchema } from "../../../../../../services/formValidation";
import {
  useCreateNavbarLink,
  useDeleteNavbarLink,
  useGetNavbarLinks,
} from "../../../../../../utils/react-query/querySupplier/queries";
import NavbarLinksForm from "../NavbarLinksForm/NavbarLinksForm";
import NavbarLinksList from "../NavbarLinksList/NavbarLinksList";
import NavbarLinksModalManager from "../NavbarLinksModalManager/NavbarLinksModalManager";

const MAX_NAVBAR_LINKS = 10;

const NavbarLinksPreferences = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState<NavbarLink | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [linkToDelete, setLinkToDelete] = useState<number | null>(null);

  // Query
  const { data: links } = useGetNavbarLinks();
  const { mutateAsync: createLink, isLoading: isCreatelinkLoading } =
    useCreateNavbarLink();
  const { mutateAsync: deleteLink, isLoading: isDeleteLinkLoading } =
    useDeleteNavbarLink();

  const { control, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(navbarLinksFormSchema),
    defaultValues: {
      name: "",
      url: "",
    },
  });

  const onSubmit = (data: NewNavbarLink) => {
    createLink(data);
    // Clear form fields after submit
    reset();
  };

  const handleOpenDeleteDialog = (linkId: number) => {
    setLinkToDelete(linkId);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setLinkToDelete(null);
  };

  const onDeleteItem = () => {
    if (linkToDelete !== null) {
      deleteLink(linkToDelete);
      setDeleteDialogOpen(false);
    }
  };

  const handleEdit = (link: NavbarLink) => {
    if (link) {
      //Stores the selected link
      setSelectedLink(link);
      // open the modal
      setIsModalOpen(true);
    }
  };

  // Check if links are equal or more than the maximum allowed
  const isMaxLinksReached = links?.length >= MAX_NAVBAR_LINKS;

  return (
    <div>
      <NavbarLinksModalManager
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        selectedLink={selectedLink!}
      />
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        title="Confirmar eliminación"
        contentText="¿Estás seguro de que deseas eliminar este Link?"
        cancelText="No, Cancelar"
        confirmText="Sí, eliminar este link"
        onConfirm={onDeleteItem}
      />
      <Paper className="paper-container">
        <div className="banner-section-header">
          <h1 className="banner-section-title">Gestionar Enlaces del Navbar</h1>
          <p className="banner-section-description">
            Agrega, edita o elimina fácilmente los enlaces que aparecerán en la
            barra de navegación de tu sitio web. Asegúrate de que cada enlace
            esté correctamente etiquetado y tenga una URL válida. Puedes agregar
            hasta un máximo de 10 enlaces.
          </p>
        </div>
        <hr className="hr-separator" />
        <div>
          <NavbarLinksForm
            onSubmit={handleSubmit(onSubmit)}
            control={control}
            errors={errors}
            isLoading={isCreatelinkLoading}
            isDisabled={isMaxLinksReached}
          />
          <NavbarLinksList
            links={links}
            handleEdit={handleEdit}
            handleDelete={handleOpenDeleteDialog}
            isDeleteLoading={isDeleteLinkLoading}
          />
        </div>
      </Paper>
    </div>
  );
};

export default NavbarLinksPreferences;

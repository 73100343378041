import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

type NavbarLinksListProps = {
  links: NavbarLink[];
  handleEdit: (link: NavbarLink) => void;
  handleDelete: (linkId: number) => void;
  isDeleteLoading: boolean;
};

const NavbarLinksList = ({
  links,
  handleEdit,
  handleDelete,
  isDeleteLoading,
}: NavbarLinksListProps) => {
  return (
    <>
      {links?.map((link: NavbarLink) => (
        <div key={link.id}>
          <Divider variant="fullWidth" style={{ marginBlock: "0.8rem" }} />
          <Grid container spacing={2}>
            <Grid item xs={4} sm={4} md={5}>
              <TextField
                label="Nombre"
                size="small"
                value={link.name}
                variant="outlined"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={4} sm={4} md={5}>
              <TextField
                size="small"
                label="URL"
                value={link.url}
                variant="outlined"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={4} sm={4} md={2}>
              <IconButton onClick={() => handleEdit(link)}>
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton onClick={() => handleDelete(link.id)}>
                {isDeleteLoading ? (
                  <CircularProgress color="primary" size={15} />
                ) : (
                  <DeleteOutlineOutlinedIcon fontSize="small" color="error" />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </div>
      ))}
    </>
  );
};

export default NavbarLinksList;

//help to query keys QueryCheckout and queryVirtualConsultancy and queryEcommerce

export enum QUERY_KEYS {
  // CHECKOUT KEYS
  GET_CITIES = "cities",
  GET_ALL_ADDRESSES = "addresses",
  GET_SHIPPING_COST = "shippingCost",
  GET_SHIPPING_COST_BY_COMPANY_NAME = "shippingCostByCompanyName",

  // VIRTUAL CONSULTANCY KEYS
  GET_DESIGNERS = "designers",

  // ECOMMERCE KEYS
  GET_CATEGORIES = "categories",
  GET_FAVORITES = "favorites",
  GET_PRODUCT_BY_ID = "productById",
  GET_COMPANY_DATA = "getCompanyData",
  GET_SALES_PERSON_BY_COMPANYNAME = "getSalesPerson",
  GET_ALL_PRODUCTS = "getAllProducts",
  GET_FILTERS = "getFilters",

  // SUPPLIER KEYS
  GET_COLORS = "getColors",
  GET_ADDRESSES = "getAddresses",
  GET_ECOMMERCE_PREFERENCES = "getEcommercePreferences",
  GET_WOMPI_PUBLIC_KEY = "getWompiPublicKey",
  GET_WOMPI_PUBLIC_KEY_BY_SUPPLIER = "getWompiPublicKeyBySupplier",
  GET_ENVIA_PUBLIC_KEY = "getEnviaPublicKey",
  GET_SALES_PERSON = "getSalesPerson",
  GET_SALES_PERSON_BY_ID = "getSalesPersonById",

  // NAVBAR LINKS KEYS
  GET_NAVBAR_LINKS = "navbarLinks",
}

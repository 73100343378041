import { FormControl } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { FieldErrors } from "react-hook-form";
import styles from "./selectInput.module.scss";

interface SelectInputProps {
  id: string;
  options?: Options;
  register: any;
  errors: FieldErrors;
}

interface Option {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  deleted_at: null;
}

type Options = Option[];

const SelectInput = ({
  id,
  register,
  errors,
  options = [],
}: SelectInputProps) => {
  return (
    <div className={styles.selectContainer}>
      <h3>¿Cómo nos conociste?</h3>
      <FormControl
        fullWidth
        variant="outlined"
        error={!!errors?.category_id?.message}
      >
        <select
          id={id}
          name="referred_from_id"
          ref={register}
          className={`${styles.selectInput} ${errors[id] ? styles.error : ""}`}
        >
          {options &&
            options.map((source) => (
              <option value={source.id} key={source.id}>
                {source.name}
              </option>
            ))}
        </select>
      </FormControl>
      {errors[id] && (
        <div className={styles.errorContent}>
          <ErrorOutlineIcon />
          <span className={styles.errorMessage}>{errors[id].message}</span>
        </div>
      )}
    </div>
  );
};

export default SelectInput;

import { yupResolver } from "@hookform/resolvers";
import { Grid, TextField } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "../../../../../../components/Button/CustomButton";
import { navbarLinksFormSchema } from "../../../../../../services/formValidation";
import { useUpdateNavbarLink } from "../../../../../../utils/react-query/querySupplier/queries";
import styles from "./NavbarLinksEditForm.module.scss";

type NavbarLinksFormProps = {
  link: NavbarLink;
  onClose: () => void;
};

const NavbarLinksEditForm = ({ link, onClose }: NavbarLinksFormProps) => {
  // Query
  const { mutateAsync: updateLink, isLoading: isUpdateLinkLoading } =
    useUpdateNavbarLink();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(navbarLinksFormSchema),
    defaultValues: {
      name: link ? link.name : "",
      url: link ? link.url : "",
    },
  });

  const onSubmit = async (data: UpdateNavbarLink) => {
    const updatedLink = await updateLink({
      id: link.id,
      name: data.name,
      url: data.url,
    });

    // Clear the form and close the modal after editing
    if (updatedLink) {
      reset();
      onClose();
    }
  };

  return (
    <div className={styles.navbarLinksForm}>
      <div className={styles.header}>
        <h2>Editar URL del Menú</h2>
        <p>
          Actualiza el nombre y la URL del enlace para reflejar los cambios en
          el menú de navegación.
        </p>
      </div>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
        className={styles.content}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Controller
              name="name"
              control={control}
              render={({ onChange, onBlur, value, name, ref }) => (
                <TextField
                  size="small"
                  label="Nombre"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  inputRef={ref}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              name="url"
              control={control}
              render={({ onChange, onBlur, value, name, ref }) => (
                <TextField
                  size="small"
                  label="URL"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  inputRef={ref}
                  error={!!errors.url}
                  helperText={errors.url?.message}
                />
              )}
            />
          </Grid>
          <Grid container className={styles.buttonContainer}>
            <Grid className={styles.buttonItem}>
              <CustomButton
                onClick={() => onClose()}
                type="button"
                color="outlined"
                shape="little-rounded"
                block
              >
                Cancelar
              </CustomButton>
            </Grid>
            <Grid className={styles.buttonItem}>
              <CustomButton
                block
                type="submit"
                color="secondary"
                shape="little-rounded"
                isLoading={isUpdateLinkLoading}
              >
                Guardar cambios
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default NavbarLinksEditForm;

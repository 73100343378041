import { yupResolver } from "@hookform/resolvers";
import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import { useForm } from "react-hook-form";

import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import CustomButton from "../Button/CustomButton";
import useClientRegister from "../../hooks/useClientRegister";
import { signupFormSchemaVirtual } from "../../services/formValidation";
import InputForm from "../InputForm/InputForm";
import PasswordValidation from "../PasswordValidation/PasswordValidation";
import ShowError from "../showError/ShowError";
import CheckboxInput from "./checkboxInput/CheckboxInput ";
import styles from "./clientRegister.module.scss";
import SelectInput from "./selectInput/SelectInput";

const ClientRegisterCards = ({ secondaryAction, onSuccess }) => {
  const {
    isLoading,
    error,
    onRegister,
    handleTermsAndCond,
    termsAndCond,
    referralSources,
  } = useClientRegister(onSuccess);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(signupFormSchemaVirtual),
  });
  const [password, setPassword] = useState("");
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
  };

  const handlePasswordBlur = () => {
    setIsPasswordFocused(false);
  };

  const onSubmit = async (data) => {
    onRegister(data);
  };

  const passwordRef = useRef(null);

  useEffect(() => {
    const handleFocus = () => {
      setIsPasswordFocused(true);
    };
    const handleBlur = () => {
      setIsPasswordFocused(false);
    };
    const passwordInput = passwordRef.current;
    passwordInput?.addEventListener("focus", handleFocus);
    passwordInput?.addEventListener("blur", handleBlur);

    return () => {
      passwordInput?.removeEventListener("focus", handleFocus);
      passwordInput?.removeEventListener("blur", handleBlur);
    };
  }, [passwordRef]);

  const isVisible = isPasswordFocused;

  return (
    <div className={styles.container}>
      <PasswordValidation value={password} isVisible={isVisible} />
      <h1 className={styles.title}>Regístrate</h1>
      {error && <ShowError error={error} />}
      <form
        className={styles.form}
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <InputForm
          icon={PersonIcon}
          name="first_name"
          placeholder="Nombre"
          disabled={isLoading}
          register={register}
          errors={errors}
        />
        <InputForm
          icon={PersonIcon}
          name="last_name"
          id="last_name"
          placeholder="Apellido"
          disabled={isLoading}
          register={register}
          errors={errors}
        />
        <InputForm
          type="password"
          icon={LockIcon}
          name="password"
          placeholder="Contraseña"
          disabled={isLoading}
          register={register}
          errors={errors}
          onChange={(e) => setPassword(e.target.value)}
          onFocus={handlePasswordFocus}
          onBlur={handlePasswordBlur}
          ref={passwordRef}
        />
        <InputForm
          type="password"
          icon={LockIcon}
          name="passwordConfirm"
          placeholder="Confirma tu contraseña"
          disabled={isLoading}
          register={register}
          errors={errors}
        />
        <InputForm
          type="email"
          icon={EmailIcon}
          name="email"
          placeholder="Correo electrónico"
          disabled={isLoading}
          register={register}
          errors={errors}
        />
        <InputForm
          type="phone"
          icon={PhoneIcon}
          name="phone"
          id="phone"
          placeholder="Número de teléfono"
          disabled={isLoading}
          register={register}
          errors={errors}
        />

        <SelectInput
          id="referred_from_id"
          name="referred_from_id"
          register={register}
          errors={errors}
          options={referralSources}
        />

        <div className={styles.footerContent}>
          <CheckboxInput handleTermsAndCond={handleTermsAndCond} />
          <CustomButton
            color="primary"
            isLoading={isLoading}
            shape="little-rounded"
            disabled={!termsAndCond}
            type="onSubmit"
            block
          >
            Registrarse
          </CustomButton>
        </div>
      </form>
      <div className={styles.alreadyLogin}>
        <label htmlFor="termsCheckbox" className={styles.checkboxLabel}>
          Ya tienes una cuenta?
          {secondaryAction ? (
            <span className={styles.termsLink} onClick={secondaryAction}>
              Inicia sesión
            </span>
          ) : (
            <Link to="/login" className={styles.termsLink}>
              Inicia sesión
            </Link>
          )}
        </label>
      </div>
    </div>
  );
};

export default ClientRegisterCards;

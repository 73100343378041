import Modal from "../../../../../../components/ModalsEcommerce/Modal";
import NavbarLinksEditForm from "../NavbarLinksEditForm/NavbarLinksEditForm";

type NavbarLinksModalManagerProps = {
  isOpen: boolean;
  onClose: () => void;
  selectedLink?: NavbarLink;
};

const NavbarLinksModalManager = ({
  isOpen,
  onClose,
  selectedLink,
}: NavbarLinksModalManagerProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      body={
        selectedLink && (
          <NavbarLinksEditForm link={selectedLink} onClose={onClose} />
        )
      }
    />
  );
};

export default NavbarLinksModalManager;

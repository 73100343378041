import LoadingScreen from "../../../../components/SplitScreen/LoadingScreen";
import { useGetEcommercePreferences } from "../../../../utils/react-query/querySupplier/queries";
import BannerPreferences from "./components/BannerPreferences/BannerPreferences";
import BrandingPreferences from "./components/BrandingPreferences/BrandingPreferences";
import NavbarLinksPreferences from "./components/NavbarLinksPreferences/NavbarLinksPreferences";

const Preferences = () => {
  const { data: ecommercePreferences, isLoading: LoadingEcommercePreferences } =
    useGetEcommercePreferences();

  if (LoadingEcommercePreferences) return <LoadingScreen />;

  return (
    <div className="layout-dashboard-container">
      <section className="container">
        <div>
          <BrandingPreferences ecommPrefs={ecommercePreferences} />
        </div>
      </section>
      <section className="container margin-block">
        <div>
          <BannerPreferences ecommBanner={ecommercePreferences.banner} />
        </div>
      </section>
      <section className="container margin-block">
        <div>
          <NavbarLinksPreferences />
        </div>
      </section>
    </div>
  );
};

export default Preferences;
